import React, { useState, useEffect } from "react";
import axios from 'axios';

import { TWITCH_STREAMERS } from "../../global/js/helpers.js";

import Slider from "react-slick";

import "./twitch-streams.scss";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const API_ENDPOINT_USER = process.env.API_ENDPOINT_TWITCH_USER;
const API_ENDPOINT_STREAM = process.env.API_ENDPOINT_TWITCH_STREAM;

let oldStreams = [];

const TwitchStreams = ({ handleBeforeChange, handleAfterChange, handleClick }) => {
    const [otherStreams, setOtherStreams] = useState(<div className="twitch-streams" id="twitch-streams"></div>);

    //Settings for the stream scroller
    // Infinite is off because it breaks the current partie css
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 2,
                    slidesPerRow: 1,
                    arrows: false
                }
            }
        ]
    };

    /**
    * sets image sizing of the thumbnails
    */
    const thumbnailWidth = "400";
    const thumbnailHeight = "200";

    const createChannels = () => {
        let liveChannels = [];
        TWITCH_STREAMERS.forEach(channel => {
            axios.get(`${API_ENDPOINT_USER}?channelName=${channel}`)
                .then((res) => {
                    if (res.data.is_live == true) liveChannels.push(res.data.id)
                    if (channel == TWITCH_STREAMERS[TWITCH_STREAMERS.length - 1]) handleStreams(liveChannels)
                })
                .catch(error => {
                    console.log('Fetch error :: ', error);
                })
        })
    };

    const handleStreams = (userTokens) => {
        let currentStreams = [];
        let count = 0;
        userTokens.forEach(async token => {
            axios.get(`${API_ENDPOINT_STREAM}?userToken=${token}`)
                .then(async (res) => {
                    // Remove the url string end and replace with an actual width and height
                    let thumbnail = `${res.data.thumbnail_url?.substring(0, res.data.thumbnail_url.length - 20)}${thumbnailWidth}x${thumbnailHeight}.jpg`;
                    // Ensure that the data is not undefined when creating div 
                    if (res.data.thumbnail_url) {
                        currentStreams.push(
                            <div key={res.data.user_name} className="stream" id={res.data.user_name}>
                                <img src={thumbnail} alt={res.data.user_name} loading="lazy" />
                                <div className={"overlay"} onClickCapture={handleClick}>{res.data.user_name}</div>
                            </div>
                        );
                    }
                })
                .then(async () => {
                    count++;
                    if (oldStreams.sort().join(',') !== currentStreams.sort().join(',') && count == userTokens.length) {
                        setOtherStreams(
                            <Slider {...settings}
                                className="twitch-streams"
                                id="twitch-streams"
                                beforeChange={handleBeforeChange}
                                afterChange={handleAfterChange}
                            >
                                {currentStreams}
                            </Slider>
                        )
                        oldStreams = currentStreams;
                    }
                    if (document.getElementById("twitch-channel") !== null) {
                        let overlay = document.getElementById("twitch-channel").className;
                        if (document.getElementById(overlay) !== null) {
                            for (let i = 0; i < document.getElementsByClassName("stream").length; i++) {
                                document.getElementsByClassName("stream")[i].className = "stream";
                            }
                            document.getElementById(overlay).className = "stream active";
                        }
                    }
                }
                )
                .catch(error => {
                    console.log('Fetch error :: ', error);
                })
        })
    }

    useEffect(() => {
        oldStreams = [];
        // Initial creation of streams
        createChannels();

        // Check if there are changes every 30 secs
        const interval = setInterval(() => {
            createChannels();
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    return (
        otherStreams
    );
};

export default TwitchStreams;